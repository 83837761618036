import React, { useState, useContext} from 'react';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';

import {
  useNavigate
} from 'react-router-dom';

import Api from '../api';
import { store } from '../state/store.js';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://thesweetspot.com/">
        The Sweet Spot
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const globalState = useContext(store);
  const { dispatch } = globalState;

  let navigate = useNavigate();

  async function handleLogin() {
    setIsLoading(true);
    setHasError(false);
    try {
      let result = await Api.login({
        email: email,
        password: password
      });
      console.log(result);
      if (result.status === 200) {
        dispatch({
          type: 'UPDATE_USER',
          payload: result.data.user,
        });
        dispatch({
          type: 'UPDATE_AUTH_TOKEN',
          payload: result.data.authToken,
        });
        dispatch({
          type: 'UPDATE_REFRESH_TOKEN',
          payload: result.data.refreshToken,
        });
        navigate('/dashboard');
      } else {
        console.error('Error signing in', result);
        setHasError(true);
      }
    } catch (e) {
      console.error('There was an error signing in', e);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          TSS POS | Sign in
        </Typography>
        { hasError && (
          <Alert severity="error">There was an error logging in.</Alert>
        )}
        <Box noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <LoadingButton onClick={handleLogin} loading={isLoading} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}