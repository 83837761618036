import React, { useState, useEffect, useContext } from 'react';

import {
  useParams
} from 'react-router-dom';

import moment from 'moment';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';

import Api from '../../api';
import SidebarHeader from '../../components/SidebarHeader';
import FileObjViewer from '../../components/FileObjViewer';

export default function Customer() {
  const [customer, setCustomer] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isSizingOpen, setIsSizingOpen] = useState(false);
  const [sizeStance, setSizeStance] = useState('competitive');
  const [sizeSurface, setSizeSurface] = useState('gravel');
  const [isSizingLoading, setIsSizingLoading] = useState(false);
  const [sizingResult, setSizingResult] = useState(null);
  const [didSizingError, setDidSizingError] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState('');

  const [customerSaveState, setCustomerSaveState] = useState('');
  const [confirmResetPassword, setConfirmResetPassword] = useState(false);
  const [resetState, setResetState] = useState('');

  const { id } = useParams();

  useEffect(() => {
    async function getCustomer() {
      setIsLoading(true);
      setHasError(false);
      try {
        let result = await Api.getCustomer(id);
        console.log(result);
        if (result.status === 200) {
          setCustomer(result.data);
          setStripeCustomerId(result.data.customer.stripeCustomerId);
        }
      } catch (e) {
        console.error('Error getting the customer', e);
        setHasError(true);
        setErrorMessage('Error getting the customer');
      } finally {
        setIsLoading(false);
      }
    }
    if (shouldRefresh) {
      getCustomer();
    }
  }, [id, shouldRefresh]);

  async function getSizing() {
    try {
      setIsSizingLoading(true);
      let result = await Api.getSizing({
        id: id,
        surface: sizeSurface,
        stance: sizeStance
      });

      console.log('sizing result', result);

      if (result.status === 200) {
        setSizingResult(result.data);
      } else {
        console.error('There was an error getting the customers sizing');
      }
    } catch (e) {
      console.error('There was an error getting the customers sizing', e);
      setDidSizingError(true);
    } finally {
      setIsSizingLoading(false);
    }
  }

  async function saveCustomerInfo() {
    try {
      setCustomerSaveState('loading');
      let result = await Api.saveCustomerData({
        id: id,
        stripeCustomerId: stripeCustomerId
      });

      if (result.status === 200) {
        setCustomerSaveState('success');
        setShouldRefresh(true);
      } else {
        console.error('There was an error saving the customer info');
      }
    } catch (e) {
      console.error('There was an error saving the customer info', e);
      setCustomerSaveState('error');
    } finally {
      setTimeout(() => {
        setCustomerSaveState('');
      }, 3000);
    }
  }

  async function resetPassword() {
    try {
      setResetState('loading');
      let result = await Api.resetCustomerPassword({
        id: id
      });
      if (result.status === 200) {
        setResetState('success');
        setShouldRefresh(true);
        setConfirmResetPassword(false);
      } else {
        setResetState('error');
      }
    } catch (e) {
      console.error('Error resetting the password', e);
      setResetState('error');
    } finally {
      setTimeout(() => {
        setResetState('');
      }, 3000);
    }
  }
  

  function renderSubscriptionItems() {
    if (customer.customer !== undefined && customer.customer.subscriptionItems !== undefined) {
      return customer.customer.subscriptionItems.map(item => {
        return (
          <Grid item xs={12} key={item.id}>
            <Paper style={{ padding: 10, marginBottom: 10 }}>
              <Typography variant="h5" gutterBottom>
                Sub
              </Typography>
               <Typography variant="subtitle1" gutterBottom>
                { moment.utc(item.createdAt).format('MM/DD/YYYY hh:mm A')}
              </Typography>
              <p><strong>State:</strong> { item.state }</p>
              <Link href={`/subscription-item/${item.id}`}>View</Link>
            </Paper>
          </Grid>
        )
      })
    }
  }

  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
    }
    console.log('customer', customer);
    return (
      <div style={{ width: '100%'}}>
        <Grid item xs={12}>
          <Breadcrumbs style={{ marginBottom: 10 }}>
            <Link underline="hover" color="inherit" href="/customers">Customers</Link>
            <Link aria-current="page" underline="none" color="inherit">{ customer.firstName } { customer.lastName }</Link>
          </Breadcrumbs>
        </Grid>
        <Button onClick={() => setIsSizingOpen(true)} style={{ marginTop: 20 }} variant="contained">Run Sizing</Button>
        <Grid item xs={12}>
          <h3>Customer Info</h3>
          <p><strong>Height: </strong>{ customer.customer.height }</p>
        </Grid>
        <Grid item xs={3}>
          <p><strong>Stripe Customer ID</strong></p>
          <FormControl fullWidth>
            <TextField value={stripeCustomerId} onChange={(e) => setStripeCustomerId(e.target.value)} />
          </FormControl>
          <Button style={{ marginTop: 10 }} disabled={customerSaveState === 'loading'} onClick={saveCustomerInfo} variant="contained">{ customerSaveState === 'loading' ? <CircularProgress /> : 'Save' }</Button>
        </Grid>
        <Grid item xs={3}>
          <Button style={{ marginTop: 10 }} onClick={() => setConfirmResetPassword(true)} variant="contained">Reset Customer Password</Button>
        </Grid>
        <Grid item xs={12}>
          <h3>Customer Subscription Items</h3>
          { renderSubscriptionItems() }
        </Grid>
        <Dialog open={isSizingOpen} onClose={() => setIsSizingOpen(false)}>
          <DialogTitle>Sizing</DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              { customer.customer.sizingImage !== null && customer.customer.sizingImage !== undefined && (
                <FileObjViewer style={{ height: 300, width: 'auto'}} id={customer.customer.sizingImage.id} />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: '100%', marginTop: 20}}>
                <InputLabel id="surface">Surface</InputLabel>
                <Select labelId="surface" id="surface" value={sizeSurface} label="Surface" onChange={(e) => setSizeSurface(e.target.value)}>
                  <MenuItem value="road">Road</MenuItem>
                  <MenuItem value="gravel">Gravel</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ width: '100%'}}>
              <FormControl style={{ width: '100%', marginTop: 20}}>
                <InputLabel id="stance">Stance</InputLabel>
                <Select labelId="stance" id="stance" value={sizeStance} label="Stance" onChange={(e) => setSizeStance(e.target.value)}>
                  <MenuItem value="endurance">Endurance</MenuItem>
                  <MenuItem value="competitive">Competitive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            { didSizingError && (
              <Alert severity="error" style={{ marginTop: 20 }}>
                <AlertTitle>Error getting sizing</AlertTitle>
                There was an error getting the sizing information for this customer. If this continues to happen, reach out to <em>steven@thesweetspot.com</em> on Slack or email.
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsSizingOpen(false)}>Cancel</Button>
            <Button disabled={isSizingLoading} onClick={getSizing} variant="contained">{ isSizingLoading ? <CircularProgress /> : 'Get Sizing' }</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmResetPassword} onClose={() => setConfirmResetPassword(false)}>
          <DialogTitle>Confirm Reset Customer Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { resetState === 'error' && <Alert style={{ marginBottom: 20 }} severity="error">Error resetting the password</Alert> }
              Are you sure you want to reset the customer password? The system will randomly generate a new password and email it to them. This action cannot be reversed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmResetPassword(false)}>Cancel</Button>
            <Button variant="contained" onClick={resetPassword}>{ resetState === 'loading' ? <CircularProgress /> : 'Reset Password' }</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  return (
    <SidebarHeader currentPage="customers">
      <Grid container style={{ width: '100%'}}>
        <Grid item xs={12} style={{ width: '100%'}}>
          { hasError && <Alert style={{ marginBottom: 20 }} severity="error">{ errorMessage }</Alert>}
        </Grid>
        { renderBody()}
      </Grid>
    </SidebarHeader>
  )

}