import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import Api from '../api';

export default function FileObjViewer(props) {
  const { id, style } = props;

  const [assetUrl, setAssetUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    async function getUrl() {
      try {
        setIsLoading(true);
        setHasError(false);
        let result = await Api.getFileObjectDownloadUrl(id);
        if (result.status === 200) {
          setAssetUrl(result.data.url);
        } else {
          console.error('Error getting the asset url', result);
          setHasError(true);
        }
      } catch (e) {
        console.error('Error getting the asset url', e);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }
    getUrl();
  }, [id]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (hasError) {
    return <Alert severity="error">Error loading the image.</Alert>
  }
  return <img alt="img" src={assetUrl} style={style || {}} />
};

FileObjViewer.propTypes = {
  id: PropTypes.number,
  style: PropTypes.object
};