import React from 'react';

import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function ConfirmDialog(props) {
  const { isOpen, handleSuccess, handleCancel, title, description } = props;

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>Confirm: { title }</DialogTitle>
      <DialogContent>
        <DialogContentText>{ description }</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" onClick={handleSuccess}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )

}

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleSuccess: PropTypes.func,
  handleCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string
};