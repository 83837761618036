import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import PedalBikeIcon from '@mui/icons-material/PedalBike';

import {
  NavLink
} from 'react-router-dom';

import {
  store
} from '../state/store.js';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SidebarHeader(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  let { user } = state;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  function handleLogoutMenu(e) {
    setMenuAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setMenuAnchorEl(null);
  }

  function handleLogout() {
    dispatch({
      type: 'REMOVE_LOCAL_STATE'
    });
    setMenuAnchorEl(null);
  }

  const routes = [
    {
      label: 'Dashboard',
      currentPageMatch: 'dashboard',
      route: '/dashboard',
      icon: () => <HomeIcon />,
    },
    {
      label: 'Customers',
      currentPageMatch: 'customers',
      route: '/customers',
      icon: () => <PersonIcon />,
    },
    {
      label: 'Resources',
      currentPageMatch: 'resources',
      route: '/resources',
      icon: () => <EmojiTransportationIcon />,
    }, {
      label: 'Offerings',
      currentPageMatch: 'subscriptions',
      route: '/subscriptions',
      icon: () => <PedalBikeIcon />
    }, {
      label: 'Subscriptions',
      currentPageMath: 'subscriptionItems',
      route: '/subscription-items',
      icon: () => <PedalBikeIcon />
    }
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h6" noWrap component="div">The Sweet Spot | POS</Typography>
            <span style={{ marginLeft: 'auto' }}>
              <Button  color="inherit" onClick={handleLogoutMenu}>Hi {user.firstName} {user.lastName} <KeyboardArrowDownIcon /></Button>
              <Menu anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </span>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          { routes.map((route) => {
            let style = {};
            let routerStyle = {
              textDecoration: 'none',
              color: 'inherit'
            }
            if (props.currentPage === route.currentPageMatch) {
              style.color = '#F26A24';
              routerStyle.color = '#F26A24';
            }
            return (
              <NavLink style={routerStyle} to={route.route} key={route.label}>
                <ListItem style={style} button key={route.label}>
                  <ListItemIcon>
                    {route.icon()}
                  </ListItemIcon>
                  <ListItemText primary={route.label} />
                </ListItem>
              </NavLink>
            )
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        { props.children }
      </Box>
    </Box>
  )
}

SidebarHeader.propTypes = {
  currentpage: PropTypes.string
};