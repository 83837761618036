import React, { useState, useEffect, useContext, useRef } from 'react';

import PropTypes from 'prop-types';

import {
  useParams
} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import moment from 'moment';

import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import SidebarHeader from '../../components/SidebarHeader';
import ConfirmDialog from '../../components/ConfirmDialog';

import Api from '../../api';
import { store } from '../../state/store.js';

export default function ResourceSetSchedule() {
  const [resource, setResource] = useState({});
  const [coverageAreas, setCoverageAreas] = useState([]);
  const [services, setServices] = useState([]);
  const [hasJoined, setHasJoined] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [hasError, setHasError] = useState(false);

  const globalState = useContext(store);
  const { state } = globalState;
  let { user } = state;

  const [times, setTimes] = useState([]) ;
  const [editTimeObj, setEditTimeObj] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);

  let calendarRef = useRef(null);
  

  const { id } = useParams();

  useEffect(() => {

    async function getResource() {
      setIsLoading(true);
      setHasError(false);
      try {
        let result = await Api.getResource(id);
        if (result.status === 200) {
          setResource(result.data);
          setHasJoined(result.data.employees.map(x => x.id).includes(user.employee.id))
          if (result.data.coverageAreas !== null && result.data.coverageAreas !== undefined) {
            setCoverageAreas(result.data.coverageAreas);
          }
          if (result.data.services !== null && result.data.services !== undefined) {
            setServices(result.data.services);
          }
        } else {
          console.error('There was an error getting the resource', result);
          setHasError(true);
        }
      } catch (e) {
        console.error('There was an error getting the resource', e);
        setHasError(true);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }

    if (shouldRefresh) {
      getResource();
    }

  }, [shouldRefresh, id, user]);

  console.log('coverage', coverageAreas, 'services', services);

  async function selectHandler(info) {
    // Just send the time object to the API and let the API deal with it
    console.log('INSIDE SCHEDULER')
    console.log(info);
    let startTime = moment(info.startStr).utc().format();
    let endTime = moment(info.endStr).utc().format();
    let date = moment(info.startStr).startOf('day').utc().format();

    try {
      let result = await Api.newTimeslot({
        startTime: startTime,
        endTime: endTime,
        date: date,
        resourceId: id,
        newSlots: 1
      });

      // Attempt to refetch the events from the calendar API
      if (calendarRef.current !== null) {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.refetchEvents();
      } else {
        console.log('calendarRef null');
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function eventsHandler(fetchInfo, successCallback, failureCallback) {
    console.log('getting events in handler')
    let { startStr, endStr } = fetchInfo;
    let start = moment(startStr).utc();
    let end = moment(endStr).utc();
    try {
      let result = await Api.getResourceTimeslots({
        startTime: start,
        endTime: end,
        resourceId: id
      });
  
      console.log('from schedule handler', result.data);

      // format the events
      let events = result.data.map(elem => {
        return {
          start: elem.startTime,
          end: elem.endTime,
          title: `Open Slots: ${elem.availableSlots}`,
          availableSlots: elem.availableSlots
        }
      });

      successCallback(events);
    } catch (e) {
      failureCallback();
    }
  }

  async function eventResizeHandler(info) {
    let original = info.oldEvent;
    let newEvent = info.event;

    console.log('info', info);

    let orig = {
      startTime: moment(original.start.toISOString()).toISOString(),
      endTime: moment(original.end.toISOString()).toISOString(),
      resourceId: id,
      availableSlots: 1
    };
    
    let newEv = {
      startTime: moment(newEvent.start.toISOString()).toISOString(),
      endTime: moment(newEvent.end.toISOString()).toISOString(),
      resourceId: id,
      availableSlots: 1
    };

    // Delete the original, create the new env
    try {
      let res = await Api.deleteTimeslot(orig);
      await Api.newTimeslot(newEv);

      if (calendarRef.current !== null) {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.refetchEvents();
      } else {
        console.log('calendarRef null');
      }

    } catch (e) {
      console.error('There was an error making the request', e);
    }
  }

  async function eventDropHandler(info) {
    let original = info.oldEvent;
    let newEvent = info.event;

    let orig = {
      startTime: moment(original.start.toISOString()).toISOString(),
      endTime: moment(original.end.toISOString()).toISOString(),
      resourceId: id,
      availableSlots: 1
    };

    let newEv = {
      startTime: moment(newEvent.start.toISOString()).toISOString(),
      endTime: moment(newEvent.end.toISOString()).toISOString(),
      resourceId: id,
      availableSlots: 1
    };

    // Delete the original, create the new env
    try {
      let res = await Api.deleteTimeslot(orig);
      await Api.newTimeslot(newEv);

      if (calendarRef.current !== null) {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.refetchEvents();
      } else {
        console.log('calendarRef null');
      }

    } catch (e) {
      console.error('There was an error making the request', e);
    }
  }

  function handleEventClick(info) {
    let event = info.event;
    console.log('event click', event, event.start, event.title);
    let availableSlots = event.title.split(':')[1].trim()
    let newEv = {
      startTime: moment(event.start.toISOString()).toISOString(),
      endTime: moment(event.end.toISOString()).toISOString(),
      availableSlots: parseInt(availableSlots)
    };

    console.log('newEnv', newEv);

    setEditTimeObj(newEv)
    setShowEditModal(true);

    // setDeleteTimeObj(newEv);

    // Prompt for delete
    // setShowDeleteModal(true);
  }

  async function deleteTimestamp() {
    // try {
    //   let res = await Api.deleteProviderTimeslot(deleteTimeObj);
    //   setShowDeleteModal(false);
    //   if (calendarRef.current !== null) {
    //     let calendarApi = calendarRef.current.getApi()
    //     calendarApi.refetchEvents();
    //   } else {
    //     console.log('calendarRef null');
    //   }

    // } catch (e) {
    //   console.error('There was an error making the request', e);
    // }
  }

  function handleCalendarRefresh() {
    let calendarApi = calendarRef.current.getApi()
    calendarApi.refetchEvents();
  }

  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )
    }

    return (
      <div style={{ width: '100%'}}>
        <Grid item xs={12} style={{ width: '100%'}}>
          <Breadcrumbs style={{ marginBottom: 10 }}>
            <Link underline="hover" color="inherit" href="/resources">Resources</Link>
            <Link underline="hover" href={`/resource/${id}`} color="inherit">{ resource.name }</Link>
            <Link aria-current="page" underline="none" color="inherit">Set Schedule</Link>
          </Breadcrumbs>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h4">Set Schedule: { resource.name }</Typography>
          </div>
          <Typography variant="p1"><strong>Coverage Areas: </strong> {coverageAreas.map(elem => elem.name).join(', ')}</Typography>
          <br />
          <Typography variant="p1"><strong>Services: </strong> {services.map(elem => elem.name).join(', ')}</Typography>
          {
            !hasJoined && <Alert style={{ marginTop: 20 }} severity="error">You have not joined this resource.</Alert>
          }
          {
            hasJoined && (
              <FullCalendar 
                ref={calendarRef}
                defaultView="timeGridWeek"
                allDaySlot={false}
                plugins={[ timeGridPlugin, interactionPlugin ]} 
                events={eventsHandler}
                eventResize={eventResizeHandler}
                eventDrop={eventDropHandler}
                selectable={true}
                draggable={true}
                editable={true}
                selectMirror={true}
                select={selectHandler}
                slotDuration={'00:30:00'}
                slotLabelInterval={30}
                eventClick={handleEventClick}
                eventColor={'#333537'}
                eventBackgroundColor={'#333537'}
                eventBorderColor={'#333537'}
              />
            )
          }
          <EditTime isOpen={showEditModal} onClose={() => setShowEditModal(false)} time={editTimeObj} resourceId={id} onRefresh={() => handleCalendarRefresh()} />
        </Grid>
      </div>
    )
  }

  return (
    <SidebarHeader currentPage="resources">
      <Grid container style={{ width: '100%'}}>
        { hasError && <Alert style={{ marginBottom: 20 }} severity="error">There was an error getting the resource.</Alert>}
        { renderBody( )}
      </Grid>
    </SidebarHeader>
  )

}

function EditTime(props) {
  const { isOpen, onClose, time, resourceId, onRefresh } = props;

  const [availableSlots, setAvailableSlots] = useState(1);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isUpdateSlotsLoading, setIsUpdateSlotsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function updateSlots() {
    try {
      setIsUpdateSlotsLoading(true);
      let result = await Api.updateTimeslotOpenings({
        resourceId: resourceId,
        startTime: time.startTime,
        endTime: time.endTime,
        availableSlots: availableSlots
      });
      if (result.status === 200) {
        setIsUpdateSlotsLoading(false);
        onRefresh();
        onClose();
      } else {
        console.error('There was an error updating the timeslot openings');
        setErrorMessage('Error updating the timeslot openings');
        setHasError(true);
      }
    } catch (e) {
      console.error('There was an error updating the timeslot openings', e);
      setErrorMessage('Error updating the timeslot openings');
      setHasError(true);
    } finally {
      setIsUpdateSlotsLoading(false);
    }
  }

  async function handleDelete() {
    try {
      let result = await Api.deleteTimeslot({
        resourceId: resourceId,
        startTime: time.startTime,
        endTime: time.endTime
      });
      if (result.status === 200) {
        onRefresh();
        onClose();
      } else {
        console.error('There was an error deleting the timeslot');
        setErrorMessage('Error deleting the timeslot');
        setHasError(true);
      }
    } catch (e) {
      console.error('There was an error deleting the timeslot');
      setErrorMessage('Error deleting the timeslot');
      setHasError(true);
    } finally {
      setConfirmDelete(false);
    }
  }

  useEffect(() => {
    setAvailableSlots(time.availableSlots);
  }, [time])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Time Slot</DialogTitle>
      <DialogContent>
        { hasError && <Alert severity="error">{ errorMessage }</Alert>}
        <TextField autoFocus margin="dense" type="number" label="Available Slots" fullWidth value={availableSlots} onChange={(e) => setAvailableSlots(e.target.value)} />
        <DialogContentText style={{ marginTop: 10, marginBottom: 10}}>
          You can delete the timeslot if there are no engagements scheduled for it.
        </DialogContentText>
        <Button onClick={() => setConfirmDelete(true)} variant="contained" color="error">Delete Time</Button>
        <ConfirmDialog 
          isOpen={confirmDelete}
          handleCancel={() => setConfirmDelete(false)}
          handleSuccess={handleDelete} 
          title="Confirm Delete Time?"
          description="Are you sure you want to delete this time?"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={updateSlots} variant="contained" loading={isUpdateSlotsLoading}>Update Available Slots</LoadingButton>
      </DialogActions>
    </Dialog>
  )
};

EditTime.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  time: PropTypes.object,
  resourceId: PropTypes.number,
  onRefresh: PropTypes.func
}