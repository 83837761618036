import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import moment from 'moment';

import SidebarHeader from '../components/SidebarHeader';

import Api from '../api';

export default function Dashboard() {

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [encounters, setEncounters] = useState([]);

  useEffect(() => {
    async function getEncounters() {
      try {
        setIsLoading(true);
        let result = await Api.getUpcomingEncounters();
        if (result.status === 200) {
          console.log(result);
          setEncounters(result.data);
        } else {
          throw new Error(result.status);
        }
      } catch (e) {
        setHasError(true);
        console.error('Error getting upcoming encounters', e);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  function renderEncounters() {
    if (encounters.length === 0) {
      return (
        <p><strong>No Encounters</strong></p>
      )
    }
    return encounters.map(encounter => {
      return (
        <Grid xs={12}>
          <hr />
          <p><strong>Service: </strong>{encounter.service.name}</p>
          <p><strong>Description: </strong>{encounter.service.description}</p>
          <p><strong>State: </strong>{encounter.state}</p>
          <p><strong>Duration: </strong>{encounter.service.duration} minutes</p>
          <p><strong>Start Time: </strong>{moment(encounter.timeSlots[0].startTime).format('MM/DD/YYYY hh:mm A')}</p>
          <Link underline="hover" href={`/subscription-item/${encounter.subscriptionItem.id}`}><Button variant="contained">View Subscription</Button></Link>
        </Grid>
      )
    })
  }

  return (
    <SidebarHeader currentPage="dashboard">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Dashboard</Typography>
          { hasError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              There was an error getting the encounters.
            </Alert>
          )}
          <h3>Encounters</h3>
          <Grid container>
            { renderEncounters() }
          </Grid>
        </Grid>
      </Grid>
    </SidebarHeader>
  )
}