import React, { useState, useEffect, useContext } from 'react';

import {
  useParams
} from 'react-router-dom';

import moment from 'moment';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Switch from '@mui/material/Switch';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import SidebarHeader from '../../components/SidebarHeader';
import ConfirmDialog from '../../components/ConfirmDialog';

import Api from '../../api';
import { store } from '../../state/store.js';

export default function SubscriptionItem() {
  const [subscriptionItem, setSubscriptionItem] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [tierOptions, setTierOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [surfaceOptions, setSurfaceOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [serialNumber, setSerialNumber] = useState('');

  const [encounters, setEncounters] = useState([]);

  const [activeTier, setActiveTier] = useState('');
  const [activeSurface, setActiveSurface] = useState('');
  const [activeProduct, setActiveProduct] = useState('');
  const [activeColor, setActiveColor] = useState('');
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = React.useState(
    new Date()
  );
  const [stripeEndDate, setStripeEndDate] = useState(new Date());
  const [subscriptionTermLength, setSubscriptionTermLength] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [wantsToRace, setWantsToRace] = useState(false);
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('');

  const [bikeState, setBikeState] = useState('');

  const [isSanitySaveLoading, setIsSanitySaveLoading] = useState(false);
  const [isControlsLoading, setIsControlsLoading] = useState(false);

  const { id } = useParams();

  const globalState = useContext(store);
  const { state } = globalState;
  let { user } = state;

  useEffect(() => {

    async function getSubscriptionItem() {
      setIsLoading(true);
      setHasError(false);
      try {
        let [result, sanityResult, encounterResult] = await Promise.all([Api.getSubscriptionItem(id), Api.getAllSanity(), Api.getSubscriptionItemEncounters(id)]);
        console.log(result, sanityResult, encounterResult);
        if (result.status === 200 && sanityResult.status === 200 && encounterResult.status === 200) {
          if (result.data.product === undefined) {
            result.data.product = {};
          }
          setSubscriptionItem(result.data);
          setTierOptions(sanityResult.data.tiers);
          setProductOptions(sanityResult.data.products);
          setSurfaceOptions(sanityResult.data.surfaces);
          setColorOptions(sanityResult.data.colors);
          setActiveTier(result.data.cmsTierId)
          setActiveSurface(result.data.cmsSurfaceId)
          setActiveProduct(result.data.cmsProductId)
          setActiveColor(result.data.cmsColorId);
          setEstimatedDeliveryDate(result.data.estimatedDate);
          setSubscriptionEndDate(result.data.subscriptionEndDate || new Date());
          setSubscriptionStartDate(result.data.subscriptionStartDate || null);
          setSubscriptionTermLength(result.data.subscriptionTermLength || '12 months');
          setSerialNumber(result.data.serialNumber);
          setEncounters(encounterResult.data);
          setWantsToRace(result.data.wantsToRace || false);
          setStripeSubscriptionId(result.data.stripeSubscriptionId);
          setBikeState(result.data.state);
          setStripeEndDate(result.data.stripeEndDate || new Date());
        } else {
          console.error('There was an error getting the subscriptionItem', result);
          setErrorMessage('There was an error getting the subscriptionItem');
          setHasError(true);
        }
      } catch (e) {
        console.error('There was an error getting the subscriptionItem', e);
        setErrorMessage('There was an error getting the subscriptionItem');
        setHasError(true);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }

    if (shouldRefresh) {
      getSubscriptionItem();
    }

  }, [shouldRefresh, id, user]);

  function renderAlerts() {
    switch (subscriptionItem.state) {
      case "sizingRequired":
        return <Alert severity="warning">
          <AlertTitle>Sizing Required</AlertTitle>
          The customer needs to upload the sizing image.
        </Alert>
      case "depositRequired":
        return <Alert severity="warning">
          <AlertTitle>Deposit Required</AlertTitle>
          The customer needs to pay the deposit to reserve their bike.
        </Alert>
      case "buildingBike":
        return <Alert severity="info">
          <AlertTitle>Building Bike</AlertTitle>
          The customer has paid their deposit, and we now are building their bike.
        </Alert>
      case "waitingToSubscribe":
        return <Alert severity="warning">
          <AlertTitle>Waiting for Customer to Subscribe</AlertTitle>
          The customer has been prompted to subscribe before they can select a delivery date.
        </Alert>
      case "readyForDelivery":
        return <Alert severity="warning">
          <AlertTitle>Ready for Delivery</AlertTitle>
          The bike is ready for delivery, and the customer has been prompted to select a delivery date.
        </Alert>
      case "waitingForDelivery":
        return <Alert severity="warning">
          <AlertTitle>Waiting for Delivery</AlertTitle>
          The customer has selected a delivery date, and we are waiting to deliver.
        </Alert>
      case "normal":
        return <Alert severity="success">
          <AlertTitle>Normal Operations</AlertTitle>
          There are no pending alerts or status changes for this bike
        </Alert>
      case "needsService":
        return <Alert severity="error">
          <AlertTitle>Needs Service</AlertTitle>
          TSS has requested that the bike needs regular service. We have prompted the customer to select a time.
        </Alert>
      case "serviceScheduled":
        return <Alert severity="info">
          <AlertTitle>Service Scheduled</AlertTitle>
          The customer has picked a time for service.
        </Alert>
      case "customerRequestsService":
        return <Alert severity="warning">
          <AlertTitle>Customer Requests Service</AlertTitle>
          The customer has requested service and is selecting a time and date.
        </Alert>
      case "error":
        return <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          There weas an error.
        </Alert>
      default: return null;
    }
  }

  function renderControls() {
    let buttons = [];

    switch (subscriptionItem.state) {
      case "buildingBike":
        return <div style={{ marginTop: 20 }}>
          <Button disabled={isControlsLoading || activeProduct.length === 0} onClick={markReadyForDelivery} variant="contained">{isControlsLoading ? <CircularProgress /> : 'Mark Ready for Delivery'}</Button>
        </div>
      case "waitingForDelivery":
        return <div style={{ marginTop: 20 }}>
          <Button disabled={isControlsLoading || activeProduct.length === 0} onClick={() => setItemState('normal')} variant="contained">{isControlsLoading ? <CircularProgress /> : 'Delivery Complete'}</Button>
        </div>
      case "normal":
        return <div style={{ marginTop: 20 }}>
          <Button disabled={isControlsLoading || activeProduct.length === 0} onClick={() => setItemState('needsService')} variant="contained">{isControlsLoading ? <CircularProgress /> : 'Needs Service'}</Button>
        </div>
      case "needsService":
      case "customerRequestsService":
        return <div style={{ marginTop: 20}}>
          <Button disabled={isControlsLoading || activeProduct.length === 0} onClick={() => setItemState('normal')} variant="contained">{isControlsLoading ? <CircularProgress /> : 'Cancel Needs Service'}</Button>
        </div>
      case "serviceScheduled":
        return <div style={{ marginTop: 20}}>
          <Button disabled={isControlsLoading || activeProduct.length === 0} onClick={() => setItemState('normal')} variant="contained">{isControlsLoading ? <CircularProgress /> : 'Cancel Needs Service'}</Button>
          <Button style={{ marginLeft: 20 }} disabled={isControlsLoading || activeProduct.length === 0} onClick={() => setItemState('normal')} variant="contained">{isControlsLoading ? <CircularProgress /> : 'Service Complete'}</Button>
        </div>
      default:
        break;
    }

    return buttons;
  }

  async function sanitySave() {
    try {
      setIsSanitySaveLoading(true);
      let result = await Api.updateSubscriptionItemSanity({
        cmsTierId: activeTier,
        cmsProductId: activeProduct,
        cmsSurfaceId: activeSurface,
        cmsColorId: activeColor,
        id: id,
        estimatedDate: estimatedDeliveryDate,
        subscriptionTermLength: subscriptionTermLength,
        serialNumber: serialNumber,
        wantsToRace: wantsToRace,
        stripeSubscriptionId: stripeSubscriptionId,
        subscriptionEndDate: subscriptionEndDate,
        stripeEndDate: stripeEndDate
      });

      if (result.status === 200) {
        setShouldRefresh(true);
      } else {
        console.error('There was an error saving sanity');
        throw new Error('Error saving sanity');
      }
    } catch (e) {
      console.error('Error saving sanity', e);
      setHasError(true);
      setErrorMessage('There was an error saving the sanity information');
    } finally {
      setIsSanitySaveLoading(false);
      setTimeout(() => {
        setHasError(false);
        setErrorMessage('');
      }, 3000);
    }
  }

  async function markReadyForDelivery() {
    try {
      setIsControlsLoading(true);
      let result = await Api.setReadyForDelivery({
        id: id
      });
      if (result.status === 200) {
        setShouldRefresh(true);
      } else {
        console.error('There was an error marking ready for delivery');
        throw new Error('There was an error marking ready for delivery');
      }
    } catch (e) {
      console.error('Error marking ready for delivery', e);
      setHasError(true);
      setErrorMessage('Error marking ready for delivery');
    } finally {
      setIsControlsLoading(false);
      setTimeout(() => {
        setHasError(false);
        setErrorMessage('');
      }, 3000);
    }
  }
 
  async function setItemState(state) {
    try {
      setIsControlsLoading(true);
      let result = await Api.updateSubscriptionItemState({
        id: id,
        state: state
      });
      if (result.status === 200) {
        setShouldRefresh(true);
      } else {
        console.error('There was an error updating the item state');
        throw new Error('There was an error updating the item state');
      }
    } catch (e) {
      console.error('Error updating the item state', e);
      setHasError(true);
      setErrorMessage('Error updating the item state');
    } finally {
      setIsControlsLoading(false);
      setTimeout(() => {
        setHasError(false);
        setErrorMessage('');
      }, 3000);
    }
  }

  function renderStateUpdate() {
    // console.log('bike state', bikeState);
    return (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="stateUpdate">Bike State</InputLabel>
            <Select
              labelId="stateUpdate"
              id="stateUpdate"
              value={bikeState}
              label="Tier"
              onChange={(e) => setBikeState(e.target.value)}
            >
              { ['created', 'sizingRequired', 'depositRequired', 'buildingBike', 'waitingToSubscribe', 'readyForDelivery', 'waitingForDelivery', 'normal', 'customerRequestsService', 'needsService', 'serviceScheduled', 'error'].map((bState, idx) => {
                return <MenuItem key={idx} value={bState}>{ bState }</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={() => setItemState(bikeState)} variant="contained" disabled={isControlsLoading} >{isControlsLoading ? <CircularProgress /> : 'Save Bike State'}</Button>
        </Grid>
      </Grid>
    )
  }

  function renderEncounters() {
    if (encounters.length === 0) {
      return (
        <p><strong>No Encounters</strong></p>
      )
    }
    return encounters.map(encounter => {
      return (
        <Grid xs={12}>
          <hr />
          <p><strong>Service: </strong>{encounter.service.name}</p>
          <p><strong>Description: </strong>{encounter.service.description}</p>
          <p><strong>State: </strong>{encounter.state}</p>
          <p><strong>Duration: </strong>{encounter.service.duration} minutes</p>
          <p><strong>Start Time: </strong>{moment(encounter.timeSlots[0].startTime).format('MM/DD/YYYY hh:mm A')}</p>
        </Grid>
      )
    })
  }


  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
    }

    return (
      <div style={{ width: '100%'}}>
        <Grid item xs={12}>
          <Breadcrumbs style={{ marginBottom: 10 }}>
            <Link underline="hover" color="inherit" href="/subscription-items">Subscription Items</Link>
            <Link aria-current="page" underline="none" color="inherit">{ subscriptionItem.id }</Link>
          </Breadcrumbs>
        </Grid>
        { renderAlerts() }
        { renderControls() }
        { renderStateUpdate() }
          <Grid item xs={12}>
            <h3>Customer Information</h3>
          </Grid>
        <Grid container>
          <Grid item xs={6}>
            <p><strong>First Name:</strong> { subscriptionItem.customer.user.firstName}</p>
            <p><strong>Last Name:</strong> { subscriptionItem.customer.user.lastName}</p>
            <p><strong>Email:</strong> { subscriptionItem.customer.user.email}</p>
            <p><strong>Phone Number:</strong> { subscriptionItem.customer.phoneNumber}</p>
            <p><strong>Member Since:</strong> { moment(subscriptionItem.customer.user.createdAt).format('MM/DD/yyyy')}</p>
            <p><strong>Tier:</strong> {subscriptionItem.tier.name }</p>
          </Grid>
          <Grid item xs={6}>
            <p><strong>Address:</strong></p>
            <p>{ subscriptionItem.customer.address.streetAddressLine1 } <br />
            { subscriptionItem.customer.address.streetAddressLine2.length > 0 && subscriptionItem.customer.address.streetAddressLine2}
            { subscriptionItem.customer.address.city }, { subscriptionItem.customer.address.state } <br />
            { subscriptionItem.customer.address.zipCode }</p>
            <p><strong>Coverage Area:</strong> {subscriptionItem.customer.coverageArea.name}</p>
            <p><strong>Surface Type:</strong> {subscriptionItem.surface.name }</p>
          </Grid>
          <Grid item xs={12}>
            <h3>Bike Information</h3>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <p><strong>Active Tier:</strong> {subscriptionItem.tier.name }</p>
            { subscriptionItem.state === 'depositRequired' && (
              <FormControl fullWidth>
                <InputLabel id="tierUpdate">Tier</InputLabel>
                <Select
                  labelId="tierUpdate"
                  id="tierUpdate"
                  value={activeTier}
                  label="Tier"
                  onChange={(e) => setActiveTier(e.target.value)}
                >
                  { tierOptions.map(tier => {
                    return <MenuItem key={tier._id} value={tier._id}>{ tier.name }</MenuItem>;
                  })}
                </Select>
              </FormControl>
            )}
            <small>Tier can only be changed before the customer has paid a deposit.</small>
            <p><strong>Active Surface Type:</strong> {subscriptionItem.surface.name }</p>
            <FormControl fullWidth>
              <InputLabel id="surfaceUpdate">Surface Type</InputLabel>
              <Select
                labelId="surfaceUpdate"
                id="surfaceUpdate"
                value={activeSurface}
                label="Surface Type"
                onChange={(e) => setActiveSurface(e.target.value)}
              >
                { surfaceOptions.map(surface => {
                  return <MenuItem key={surface._id} value={surface._id}>{ surface.name }</MenuItem>;
                })}
              </Select>
            </FormControl>
            <p><strong>Active Product:</strong> {subscriptionItem.product.name || 'None'}</p>
            <FormControl fullWidth>
              <InputLabel id="productUpdate">Product</InputLabel>
              <Select
                labelId="productUpdate"
                id="productUpdate"
                value={activeProduct}
                label="Product Type"
                onChange={(e) => setActiveProduct(e.target.value)}
              >
                { productOptions.filter(product => product.surface._id === activeSurface).map(product => {
                  return <MenuItem key={product._id} value={product._id}>{ product.name + ' - ' + product.description }</MenuItem>;
                })}
              </Select>
            </FormControl>
            <p><strong>Active Color:</strong> { subscriptionItem.color !== undefined ? subscriptionItem.color.name : 'None'}</p>
            <FormControl fullWidth>
              <InputLabel id="colorUpdate">Color</InputLabel>
              <Select
                labelId="colorUpdate"
                id="colorUpdate"
                value={activeColor}
                label="Color"
                onChange={(e) => setActiveColor(e.target.value)}
              >
                { colorOptions.filter(color => color.surface._id === activeSurface).map(color => {
                  return <MenuItem key={color._id} value={color._id}>{ color.name }</MenuItem>;
                })}
              </Select>
            </FormControl>
            <p><strong>Serial Number:</strong></p>
            <FormControl fullWidth>
                <TextField value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <p><strong>Estimated Delivery Date</strong></p>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker 
                    label="Estimated Delivery Date"
                    inputFormat="MM/DD/yyyy"
                    value={estimatedDeliveryDate}
                    onChange={(e) => setEstimatedDeliveryDate(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
            </FormControl>
            { subscriptionStartDate !== null && (
              <div>
                <p><strong>Subscription Start Date</strong></p>
                <p>{ moment(subscriptionStartDate).format("dddd, MMMM Do YYYY, h:mm:ss a") }</p>
              </div>
            )}
            { subscriptionEndDate !== null && (
               <div>
                <p><strong>Subscription End Date</strong></p>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker 
                        label="Subscription End Date"
                        inputFormat="MM/DD/yyyy"
                        value={subscriptionEndDate}
                        onChange={(e) => setSubscriptionEndDate(e)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                </FormControl>
              </div>
            )}
            { stripeEndDate !== null && (
              <div>
                <p><strong>Stripe End Date</strong></p>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker 
                        label="Stripe End Date"
                        inputFormat="MM/DD/yyyy"
                        value={stripeEndDate}
                        onChange={(e) => setStripeEndDate(e)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                </FormControl>
              </div>
            )}
            <div>
              <p><strong>Subscription Lease Term</strong></p>
              <Select labelId="leaseTerm" id="leaseTerm" value={subscriptionTermLength} onChange={(e) => setSubscriptionTermLength(e.target.value)}>
                <MenuItem value="12 months">12 months</MenuItem>
                <MenuItem value="18 months">18 months</MenuItem>
                <MenuItem value="24 months">24 months</MenuItem>
              </Select>
            </div>
            <div>
              <p><strong>Stripe Subscription Id</strong></p>
              <FormControl fullWidth>
                <TextField value={stripeSubscriptionId} onChange={(e) => setStripeSubscriptionId(e.target.value)} />
              </FormControl>
            </div>
            <div>
              <p><strong>Wants to race?</strong></p>
              <Switch checked={wantsToRace} onChange={() => setWantsToRace(!wantsToRace)} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button isDisabled={isSanitySaveLoading} onClick={sanitySave} style={{ marginTop: 20 }} variant="contained">{ isSanitySaveLoading ? <CircularProgress /> : 'Save'}</Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <h3>Encounters</h3>
          </Grid>
          { renderEncounters() }
        </Grid>
      </div>
    )
  }

  return (
    <SidebarHeader currentPage="subscriptionItems">
      <Grid container style={{ width: '100%'}}>
        <Grid item xs={12} style={{ width: '100%'}}>
          { hasError && <Alert style={{ marginBottom: 20 }} severity="error">{ errorMessage }</Alert>}
        </Grid>
        { renderBody()}
      </Grid>
    </SidebarHeader>
  )

};