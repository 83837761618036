/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';

export default {
  Api(token) {
    return axios.create({
      // baseURL: 'http://localhost:1337',
      baseURL: 'https://production.api.thesweetspot.com',
      withCredentials: false,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  },

  login(payload) {
    console.log(this.Api());
    return this.Api().post('/auth/login', payload);
  },
  async refresh() {
    let state = JSON.parse(localStorage.getItem('state'));
    let {
      refreshToken
    } = state;
    try {
      let result = await this.Api(refreshToken).get('/auth/refresh');
      let authToken = result.data.authToken;
      state.authToken = authToken;
      localStorage.setItem('state', JSON.stringify(state));
      return new Promise((resolve, _) => {
        resolve(authToken);
      });
    } catch (e) {
      console.log("Error refreshing token");
      console.error(e);
      console.error(e.name + ': ' + e.message)
      console.error(JSON.stringify(e));
      return new Promise((_, reject) => {
        reject(e);
      });
    }
  },
  // Fetch an endpoint automatically checking and refreshing tokens
  async fetchEndpoint(fx) {
    let state = JSON.parse(localStorage.getItem('state'));
    let {
      authToken
    } = state;
    try {
      let result = await fx(authToken);
      return new Promise((resolve, _) => {
        resolve(result);
      });
    } catch (e) {
      console.log("ERROR", e);
      if (e.response.status === 403) {
        let authToken = await this.refresh();
        return fx(authToken);
      }
    }
  },
  async getCoverageAreas() {
    let x = (token) => {
      return this.Api(token).get('/coveragearea/get');
    };
    return this.fetchEndpoint(x);
  },
  async getPresignedUrl(payload) {
    let x = (token) => {
      return this.Api(token).get(`/file/${payload}/get-signed-url`);
    };
    return this.fetchEndpoint(x);
  },
  async getFileObjectDownloadUrl(payload) {
    let x = (token) => {
      return this.Api(token).get(`/file/${payload}/view`);
    };
    return this.fetchEndpoint(x);
  },
  async getServices() {
    let x = (token) => {
      return this.Api(token).get('/service/get');
    };
    return this.fetchEndpoint(x);
  },
  async getResources() {
    let x = (token) => {
      return this.Api(token).get('/resource/get');
    };
    return this.fetchEndpoint(x);
  },
  async getResource(payload) {
    let x = (token) => {
      return this.Api(token).get(`/resource/get/${payload}`);
    };
    return this.fetchEndpoint(x);
  },
  async getCustomers() {
    let x = (token) => {
      return this.Api(token).get('/employee/customer/get');
    };
    return this.fetchEndpoint(x);
  },
  async getCustomer(payload) {
    let x = (token) => {
      return this.Api(token).get(`/employee/customer/get/${payload}`);
    };
    return this.fetchEndpoint(x);
  },
  async joinResource(payload) {
    let x = (token) => {
      return this.Api(token).post(`/resource/employee/join`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async leaveResource(payload) {
    let x = (token) => {
      return this.Api(token).post(`/resource/employee/leave`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async newTimeslot(payload) {
    let x = (token) => {
      return this.Api(token).post(`/timeslot/new`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async deleteTimeslot(payload) {
    let x = (token) => {
      return this.Api(token).delete(`/timeslot/delete`, {
        data: payload
      });
    };
    return this.fetchEndpoint(x);
  },
  async updateTimeslotOpenings(payload) {
    let x = (token) => {
      return this.Api(token).put(`/timeslot/update-openings`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async getResourceTimeslots(payload) {
    let x = (token) => {
      return this.Api(token).post(`/timeslot/resource/get`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async getSubscriptionTiers() {
    let x = (token) => {
      return this.Api(token).get(`/sanity/tiers/get`);
    };
    return this.fetchEndpoint(x);
  },
  async getSubscriptionColors() {
    let x = (token) => {
      return this.Api(token).get(`/sanity/colors/get`);
    };
    return this.fetchEndpoint(x);
  },
  async getSubscriptionSurfaces() {
    let x = (token) => {
      return this.Api(token).get(`/sanity/surfaces/get`);
    };
    return this.fetchEndpoint(x);
  },
  async getAllSanity() {
    let x = (token) => {
      return this.Api(token).get(`/sanity/all/get`);
    };
    return this.fetchEndpoint(x);
  },
  async getSubscriptionItems(params) {
    let x = (token) => {
      return this.Api(token).get(`/subscription-item/get`, params);
    };
    return this.fetchEndpoint(x);
  },
  async getSubscriptionItem(params) {
    let x = (token) => {
      return this.Api(token).get(`/subscription-item/get/${params}`);
    };
    return this.fetchEndpoint(x);
  },
  async getSubscriptionItemEncounters(params) {
    let x = (token) => {
      return this.Api(token).get(`/employee/subscription/get-encounters/${params}`);
    };
    return this.fetchEndpoint(x);
  },
  async getUpcomingEncounters(params) {
    let x = (token) => {
      return this.Api(token).get(`/employee/encounters/upcoming`);
    };
    return this.fetchEndpoint(x);
  },
  async getSizing(payload) {
    let x = (token) => {
      return this.Api(token).post(`/employee/customer/get-sizing`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async updateSubscriptionItemSanity(payload) {
    let x = (token) => {
      return this.Api(token).post(`/subscription-item/update/sanity`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async setReadyForDelivery(payload) {
    let x = (token) => {
      return this.Api(token).post(`/subscription-item/update/ready-for-delivery`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async saveCustomerData(payload) {
    let x = (token) => {
      return this.Api(token).put(`/employee/customer/update`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async resetCustomerPassword(payload) {
    let x = (token) => {
      return this.Api(token).post(`/employee/customer/reset-password`, payload);
    };
    return this.fetchEndpoint(x);
  },
  async updateSubscriptionItemState(payload) {
    let x = (token) => {
      return this.Api(token).post(`/subscription-item/update/state`, payload);
    };
    return this.fetchEndpoint(x);
  },
}
