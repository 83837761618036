// store.js
import React, {
  createContext,
  useReducer,
  useEffect
} from 'react';

import reducer from './reducer';

const initialState = {
  user: {
    role: ''
  },
  authToken: "",
  refreshToken: ""
};

const localState = JSON.parse(localStorage.getItem("state"));

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer(reducer, localState || initialState);
  
  useEffect(() => {
    localStorage.setItem("state", JSON.stringify(state));
  }, [state]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }