const initialState = {
  user: {},
  authToken: "",
  refreshToken: ""
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload
      }
    case "UPDATE_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: action.payload
      }
    case "UPDATE_AUTH_TOKEN":
      return {
        ...state,
        authToken: action.payload
      }
    case "REMOVE_LOCAL_STATE":
      localStorage.removeItem('state');
      return initialState;
    default:
      throw new Error();
  };
}

export default reducer;