import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import {
  useNavigate
} from 'react-router-dom';


import SidebarHeader from '../../components/SidebarHeader';

import Api from '../../api';

export default function SubscriptionItems() {

  const [subscriptionItems, setSubscriptionItems] = useState([]);
  const [totalSubItemCount, setTotalSubItemCount] = useState(0);
  const [shouldReload, setShouldReload] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [pageSkip, setPageSkip] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);

  let navigate = useNavigate();

  useEffect(() => {
    async function getSubscriptionItems() {
      try {
        let result = await Api.getSubscriptionItems({
          skip: pageSkip,
          pageLimit: pageLimit
        });
        console.log(result);
        if (result.status === 200) {
          result.data.subscriptionItems = result.data.subscriptionItems.map(elem => {
            if (elem.product === undefined) {
              elem.product = {};
            }
            return elem;
          });
          setSubscriptionItems(result.data.subscriptionItems);
          setTotalSubItemCount(result.data.count);
        } else {
          console.error('Error getting the subscriptionItems');
          setHasError(true);
        }
      } catch (e) {
        console.error('Error getting the subscriptionItems', e);
        setHasError(true);
      } finally {
        setIsLoading(false);
        setShouldReload(false);
      }
    }
    if (shouldReload) {
      getSubscriptionItems();
    }
  }, [shouldReload, pageSkip, pageLimit]);

  function handleSelectedSubscriptionItem(selectionModel) {
    // Navigate to that users page
    navigate(`/subscription-item/${selectionModel}`);
  }

  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
    }

    const columns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      }, {
        field: 'state',
        headerName: 'State',
        width: 250
      }, {
        field: 'tierName',
        headerName: 'Tier',
        width: 250
      }, {
        field: 'surfaceName',
        headerName: 'Surface',
        width: 250
      }, {
        field: 'customerName',
        headerName: 'Customer',
        width: 250
      }, {
        field: 'productName',
        headerName: 'Product',
        width: 250
      }
    ];

    const rows = subscriptionItems.map(sub => {
      // if (sub.product === undefined) {
      //   sub.product.name = ""
      // }
      return {
        id: sub.id,
        state: sub.state,
        paymentStatus: sub.paymentStatus,
        hasPaidDeposit: sub.hasPaidDeposit,
        customerName: `${sub.customer.user.firstName} ${sub.customer.user.lastName}`,
        surfaceName: sub.surface.name,
        tierName: sub.tier.name,
        productName: sub.product.name || '',
        // productName: ''
      }
    });

    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            columns={columns}
            rows={rows}
            pagination
            paginationMode="server"
            rowCount={totalSubItemCount}
            onPageChange={(page) => setPageSkip(page * pageLimit)}
            pageSize={pageLimit}
            onPageSizeChange={(page) => setPageLimit(page)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            autoHeight
            onSelectionModelChange={(selectionModel) => handleSelectedSubscriptionItem(selectionModel)}
          />
        </div>
      </div>
    )
  }

  return (
    <SidebarHeader currentPage="subscriptionItems">
      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h4">Subscription Items</Typography>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20}}>
          { hasError && <Alert style={{ marginBottom: 20 }} severity="error">There was an error getting the subscription items</Alert>}
          { renderBody() }
        </Grid>
      </Grid>
    </SidebarHeader>
  )
}