import React, { useContext } from 'react';

import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useLocation
} from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  store
} from './state/store.js';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Customers from './pages/customers/Customers';
import Customer from './pages/customers/Customer';
import Resources from './pages/resources/Resources';
import Resource from './pages/resources/Resource';
import ResourceSetSchedule from './pages/resources/ResourceSetSchedule';
import Subscriptions from './pages/Subscriptions';
import SubscriptionItem from './pages/subscriptionItems/SubscriptionItem';
import SubscriptionItems from './pages/subscriptionItems/SubscriptionItems';

import './style.css';

function RequireAuth({ children, authed, ...rest }) {
  let location = useLocation();

  console.log('authed', authed);

  if (!authed) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children;
}

function App() {
  const globalState = useContext(store);
  console.log('global state', globalState);
  let { state } = globalState;
  let { authToken, refreshToken, user } = state;

  let isAuthed = authToken !== undefined && refreshToken !== undefined && authToken !== '' && refreshToken !== '';
  let isAdmin = user.role !== undefined && (user.role === "admin" || user.role === 'employee');

  const theme = createTheme({
    palette: {
      primary: {
        main: '#F26A24',
        light: '#ff9b53',
        dark: '#b83a00',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#333537',
        light: '#5c5f61',
        dark: '#0c0f11',
        contrastText: '#ffffff'
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={
              <Navigate to="/login" />
            } exact />
            <Route path="/login" element={<Login />} exact />
            <Route path="/dashboard" element={<RequireAuth authed={isAuthed && isAdmin}><Dashboard /></RequireAuth>} />
            <Route path="/customers" element={<RequireAuth authed={isAuthed && isAdmin}><Customers /></RequireAuth>} />
            <Route path="/customer/:id" element={<RequireAuth authed={isAuthed && isAdmin}><Customer /></RequireAuth>} />
            <Route path="/resources" element={<RequireAuth authed={isAuthed && isAdmin}><Resources /></RequireAuth>} />
            <Route path="/resource/:id" element={<RequireAuth authed={isAuthed && isAdmin}><Resource /></RequireAuth>} />
            <Route path="/resource/:id/schedule/set" element={<RequireAuth authed={isAuthed && isAdmin}><ResourceSetSchedule /></RequireAuth>} />
            <Route path="/subscriptions" element={<RequireAuth authed={isAuthed && isAdmin}><Subscriptions /></RequireAuth>} />
            <Route path="/subscription-items" element={<RequireAuth authed={isAuthed && isAdmin}><SubscriptionItems /></RequireAuth>} />
            <Route path="/subscription-item/:id" element={<RequireAuth authed={isAuthed && isAdmin}><SubscriptionItem /></RequireAuth>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;