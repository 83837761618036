import React, { useState, useEffect, useContext } from 'react';

import {
  useParams
} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import SidebarHeader from '../../components/SidebarHeader';
import ConfirmDialog from '../../components/ConfirmDialog';

import Api from '../../api';
import { store } from '../../state/store.js';

export default function Resource() {
  const [resource, setResource] = useState({});
  const [hasJoined, setHasJoined] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isConfirmLeaveOpen, setIsConfirmLeaveOpen] = useState(false);

  const { id } = useParams();

  const globalState = useContext(store);
  const { state } = globalState;
  let { user } = state;

  useEffect(() => {

    async function getResource() {
      setIsLoading(true);
      setHasError(false);
      try {
        let result = await Api.getResource(id);
        if (result.status === 200) {
          setResource(result.data);
          setHasJoined(result.data.employees.map(x => x.id).includes(user.employee.id))
        } else {
          console.error('There was an error getting the resource', result);
          setErrorMessage('There was an error getting the resource');
          setHasError(true);
        }
      } catch (e) {
        console.error('There was an error getting the resource', e);
        setErrorMessage('There was an error getting the resource');
        setHasError(true);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }

    if (shouldRefresh) {
      getResource();
    }

  }, [shouldRefresh, id, user]);

  async function joinResource() {
    try {
      let result = await Api.joinResource({
        id: id
      });
      if (result.status === 200) {
        setShouldRefresh(true);
      } else {
        console.error('There was an error joining the resource', result);
        setErrorMessage('There was an error joining the resource');
        setHasError(true);
      }
    } catch (e) {
      console.error('There was an error joining the resource', e);
      setErrorMessage('There was an error joining the resource');
      setHasError(true);
    } finally {
      setIsButtonLoading(false);
    }
  }
 
  async function leaveResource() {
    try {
      let result = await Api.leaveResource({
        id: id
      });
      if (result.status === 200) {
        setShouldRefresh(true);
      } else {
        console.error('There was an error leaving the resource', result);
        setErrorMessage('There was an error leaving the resource');
        setHasError(true);
      }
    } catch (e) {
      console.error('There was an error leaving the resource', e);
      setErrorMessage('There was an error leaving the resource');
      setHasError(true);
    } finally {
      setIsButtonLoading(false);
      setIsConfirmLeaveOpen(false);
    }
  }

  function handleLeaveResource() {
    setIsConfirmLeaveOpen(true);
  }

  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
    }

    if (!hasJoined) {
      return (
        <div style={{ width: '100%'}}>
        <Grid item xs={12}>
          <Breadcrumbs style={{ marginBottom: 10 }}>
            <Link underline="hover" color="inherit" href="/resources">Resources</Link>
            <Link aria-current="page" underline="none" color="inherit">{ resource.name }</Link>
          </Breadcrumbs>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h4">{ resource.name }</Typography>
          </div>
          <Typography vairant="p1">{ resource.description }</Typography>
          <div style={{ display: 'flex', marginTop: 100, alignContent: 'center', justifyContent: 'center'}}>
            <div style={{ textAlign: 'center', width: '30%'}}>
              <Typography variant="h4">Not in Resource</Typography>
              <div style={{ marginTop: 20}}>
                <Typography variant="p1">To access the resources schedule / claim customer engagements, press join.</Typography>
              </div>
              <LoadingButton onClick={joinResource} loading={isButtonLoading} style={{ marginTop: 20}} variant="contained">Join {resource.name}</LoadingButton>
            </div>
          </div>
        </Grid>
      </div>
      )
    }

    return (
      <div style={{ width: '100%'}}>
        <Grid item xs={12}>
          <Breadcrumbs style={{ marginBottom: 10 }}>
            <Link underline="hover" color="inherit" href="/resources">Resources</Link>
            <Link aria-current="page" underline="none" color="inherit">{ resource.name }</Link>
          </Breadcrumbs>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h4">{ resource.name }</Typography>
            <div style={{ marginLeft: 'auto'}}>
              <Button onClick={handleLeaveResource} style={{ marginLeft: 10 }} variant="contained" color="error">Leave Resource</Button>
              <Link href={`/resource/${id}/schedule/set`}><Button style={{ marginLeft: 10 }} variant="contained">Edit Schedule</Button></Link>
            </div>
          </div>
          <Typography vairant="p1">{ resource.description }</Typography>
          <ConfirmDialog 
            isOpen={isConfirmLeaveOpen}
            handleSuccess={leaveResource}
            title="Are you sure you want to leave?"
            description={`Are you sure you want to leave ${resource.name}?`}
            handleCancel={() => setIsConfirmLeaveOpen(false)}
          />
        </Grid>
      </div>
    )
  }

  return (
    <SidebarHeader currentPage="resources">
      <Grid container style={{ width: '100%'}}>
        <Grid item xs={12} style={{ width: '100%'}}>
          { hasError && <Alert style={{ marginBottom: 20 }} severity="error">{ errorMessage }</Alert>}
        </Grid>
        { renderBody( )}
      </Grid>
    </SidebarHeader>
  )

}