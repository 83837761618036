import React, { useState, useEffect, useContext } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

import {
  useNavigate
} from 'react-router-dom';


import SidebarHeader from '../../components/SidebarHeader';

import Api from '../../api';
import { store } from '../../state/store.js';

export default function Resources() {

  const [resources, setResources] = useState([]);
  const [totalResourceCount, setTotalResourceCount] = useState(0);
  const [shouldReload, setShouldReload] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [pageSkip, setPageSkip] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);

  let navigate = useNavigate();

  const globalState = useContext(store);
  const { state } = globalState;
  let { user } = state;

  useEffect(() => {
    async function getResources() {
      try {
        let result = await Api.getResources({
          skip: pageSkip,
          pageLimit: pageLimit
        });
        console.log(result);
        if (result.status === 200) {
          let r = result.data.resources.map(elem => {
            elem.hasJoined = elem.employees.map(x => x.id).includes(user.employee.id)
            return elem;
          });
          setResources(r);
          setTotalResourceCount(result.data.count);
        } else {
          console.error('Error getting the customers');
          setHasError(true);
        }
      } catch (e) {
        console.error('Error getting the customers', e);
        setHasError(true);
      } finally {
        setIsLoading(false);
        setShouldReload(false);
      }
    }
    if (shouldReload) {
      getResources();
    }
  }, [shouldReload, pageSkip, pageLimit, user]);

  function handleSelectedResource(selectionModel) {
    // Navigate to that users page
    navigate(`/resource/${selectionModel}`);
  }

  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
    }

    const columns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      }, {
        field: 'name',
        headerName: 'Name',
        width: 250
      }, {
        field: 'description',
        headerName: 'Description',
        width: 250
      }, {
        field: 'isMobile',
        headerName: 'Is Mobile',
        width: 250
      }, {
        field: 'coverageAreaText',
        headerName: 'Coverage Areas',
        width: 250
      }, {
        field: 'servicesText',
        headerName: 'Services',
        width: 250
      }, {
        field: 'hasJoined',
        headerName: 'Has Joined',
        width: 250
      }
    ];

    const rows = resources.map(resource => {
      if (resource.coverageAreas === undefined || resource.coverageAreas === null) {
        resource.coverageAreas = [];
      }
      if (resource.services === undefined || resource.services === null) {
        resource.services = [];
      }
      return {
        id: resource.id,
        name: resource.name,
        description: resource.description,
        isMobile: resource.isMobile,
        coverageAreas: resource.coverageAreas || [],
        coverageAreaText: resource.coverageAreas.map(elem => elem.name).join(', '),
        services: resource.services || [],
        servicesText: resource.services.map(elem => elem.name).join(', '),
        hasJoined: resource.hasJoined || false
      }
    });

    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            columns={columns}
            rows={rows}
            pagination
            paginationMode="server"
            rowCount={totalResourceCount}
            onPageChange={(page) => setPageSkip(page * pageLimit)}
            pageSize={pageLimit}
            onPageSizeChange={(page) => setPageLimit(page)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            autoHeight
            onSelectionModelChange={(selectionModel) => handleSelectedResource(selectionModel)}
          />
        </div>
      </div>
    )
  }

  return (
    <SidebarHeader currentPage="resources">
      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h4">Resources</Typography>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20}}>
          { hasError && <Alert style={{ marginBottom: 20 }} severity="error">There was an error getting the resources</Alert>}
          { renderBody() }
        </Grid>
      </Grid>
    </SidebarHeader>
  )
}