import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

import {
  useNavigate
} from 'react-router-dom';


import SidebarHeader from '../../components/SidebarHeader';

import Api from '../../api';

export default function Customers() {

  const [customers, setCustomers] = useState([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [shouldReload, setShouldReload] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [pageSkip, setPageSkip] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);

  let navigate = useNavigate();

  useEffect(() => {
    async function getCustomers() {
      try {
        let result = await Api.getCustomers({
          skip: pageSkip,
          pageLimit: pageLimit
        });
        console.log(result);
        if (result.status === 200) {
          setCustomers(result.data.customers);
          setTotalCustomerCount(result.data.count);
        } else {
          console.error('Error getting the customers');
          setHasError(true);
        }
      } catch (e) {
        console.error('Error getting the customers', e);
        setHasError(true);
      } finally {
        setIsLoading(false);
        setShouldReload(false);
      }
    }
    if (shouldReload) {
      getCustomers();
    }
  }, [shouldReload, pageSkip, pageLimit]);

  function handleSelectedCustomer(selectionModel) {
    // Navigate to that users page
    navigate(`/customer/${selectionModel}`);
  }

  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
    }

    const columns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      }, {
        field: 'firstName',
        headerName: 'First Name',
        width: 250
      }, {
        field: 'lastName',
        headerName: 'Last Name',
        width: 250
      }, {
        field: 'email',
        headerName: 'Email',
        width: 250
      }, {
        field: 'hasConfirmedEmail',
        headerName: 'Has Confirmed Email',
        width: 250
      }, {
        field: 'coverageAreaText',
        headerName: 'Coverage Areas',
        width: 250
      }
    ];

    const rows = customers.map(user => {
      if (user.customer === undefined) {
        user.customer = {
          coverageAreas: []
        };
      }
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        hasConfirmedEmail: user.hasConfirmedEmail,
        coverageAreas: user.customer.coverageAreas || [],
        coverageAreaText: user.customer.coverageAreas?.map(elem => elem.name).join(', ')
      }
    });

    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            columns={columns}
            rows={rows}
            pagination
            paginationMode="server"
            rowCount={totalCustomerCount}
            onPageChange={(page) => setPageSkip(page * pageLimit)}
            pageSize={pageLimit}
            onPageSizeChange={(page) => setPageLimit(page)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            autoHeight
            onSelectionModelChange={(selectionModel) => handleSelectedCustomer(selectionModel)}
          />
        </div>
      </div>
    )
  }

  return (
    <SidebarHeader currentPage="customers">
      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h4">Customers</Typography>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20}}>
          { hasError && <Alert style={{ marginBottom: 20 }} severity="error">There was an error getting the customers</Alert>}
          { renderBody() }
        </Grid>
      </Grid>
    </SidebarHeader>
  )
}