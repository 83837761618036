import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

import {
  useNavigate
} from 'react-router-dom';


import SidebarHeader from '../components/SidebarHeader';

import Api from '../api';

export default function Subscriptions() {

  const [tiers, setTiers] = useState([]);
  const [surfaces, setSurfaces] = useState([]);
  const [colors, setColors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);


  useEffect(() => {
    async function getSanityContent() {
      try {
        let [tierResult, surfaceResult, colorsResult] = await Promise.all([Api.getSubscriptionTiers(), Api.getSubscriptionSurfaces(), Api.getSubscriptionColors()]);
        console.log(tierResult, surfaceResult, colorsResult)
        if (tierResult.status === 200 && surfaceResult.status === 200 && colorsResult.status === 200) {
          setTiers(tierResult.data);
          setSurfaces(surfaceResult.data);
          setColors(colorsResult.data);
        } else {
          console.error('Error getting the sanity content');
          setHasError(true);
        }
      } catch (e) {
        console.error('Error getting the sanity content', e);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }
    getSanityContent()
  }, []);

  function renderBody() {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
    }

    const tierColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      }, {
        field: 'name',
        headerName: 'Name',
        width: 250
      }, {
        field: 'description',
        headerName: 'Description',
        width: 500
      }, {
        field: 'isVisible',
        headerName: 'Is Visible',
        width: 250
      }, {
        field: 'monthlyPrice',
        headerName: 'Monthly Price',
        width: 250
      }
    ];

    const tierRows = tiers.map(tier => {
      return {
        id: tier._id,
        name: tier.name,
        description: tier.description,
        isVisible: tier.isVisible,
        monthlyPrice: `$${tier.monthlyPrice}`
      }
    });
    
    const surfaceColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      }, {
        field: 'name',
        headerName: 'Name',
        width: 250
      }, {
        field: 'description',
        headerName: 'Description',
        width: 500
      }, {
        field: 'isVisible',
        headerName: 'Is Visible',
        width: 250
      }
    ];

    const surfaceRows = surfaces.map(surface => {
      return {
        id: surface._id,
        name: surface.name,
        description: surface.description,
        isVisible: surface.isVisible,
      }
    });
    
    const colorColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      }, {
        field: 'name',
        headerName: 'Name',
        width: 250
      }, {
        field: 'isVisible',
        headerName: 'Is Visible',
        width: 250
      }
    ];

    const colorRows = colors.map(color => {
      return {
        id: color._id,
        name: color.name,
        isVisible: color.isVisible,
      }
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Tiers</Typography>
          <div style={{ display: 'flex', height: '100%', marginTop: 20 }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                columns={tierColumns}
                rows={tierRows}
                pagination
                paginationMode="server"
                rowsPerPageOptions={[10, 25, 50, 100]}
                autoHeight
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 40}}>
          <Typography variant="h4">Surfaces</Typography>
          <div style={{ display: 'flex', height: '100%', marginTop: 20 }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                columns={surfaceColumns}
                rows={surfaceRows}
                pagination
                paginationMode="server"
                rowsPerPageOptions={[10, 25, 50, 100]}
                autoHeight
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 40}}>
          <Typography variant="h4">Colors</Typography>
          <div style={{ display: 'flex', height: '100%', marginTop: 20 }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                columns={colorColumns}
                rows={colorRows}
                pagination
                paginationMode="server"
                rowsPerPageOptions={[10, 25, 50, 100]}
                autoHeight
              />
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <SidebarHeader currentPage="subscriptions">
      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: 'flex', width: '100%'}}>
            <Typography variant="h4">Subscription Offerings</Typography>
            <Button style={{ marginLeft: 'auto'}}  variant="contained" href="https://tss-products.sanity.studio/desk">Edit Content</Button>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20}}>
          { hasError && <Alert style={{ marginBottom: 20 }} severity="error">There was an error getting the offerings</Alert>}
          { renderBody() }
        </Grid>
      </Grid>
    </SidebarHeader>
  )
}